<template>
  <div class="wrapper">
    <div id="column" class="world">
      <ul class="tab flex">
        <li
          v-for="(item, index) in tab"
          :key="index"
          :class="{ active: active == index }"
          @click="tabs(index)"
        >
          {{ item }}
        </li>
      </ul>
      <!-- 灵活就业报名 -->
      <section v-show="active == 0">
        <div class="flexible">
          <ul>
            <li
              class="flex cursorP"
              v-for="(item, index) in flexibleList"
              :key="index"
              @click="goRouter('/hunan/skill/skillDetail', item.id)"
            >
              <div class="text">
                <h3>
                  {{ item.name }}
                  <span class="one" v-show="item.auditStatus == '0'"
                    >未审核</span
                  >
                  <span class="two" v-show="item.auditStatus == '1'"
                    >审核通过</span
                  >
                  <el-popover
                    placement="bottom"
                    title="未通过原因"
                    width="200"
                    trigger="click"
                    content="item.auditMsg"
                  >
                    <span class="three" v-show="item.auditStatus == '2'"
                      >未通过，查看原因</span
                    >
                  </el-popover>
                </h3>
                <p class="time">报名时间：{{ item.applyTime }}</p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <!-- 竞赛报名 -->
      <section v-show="active == 1">
        <div class="activity">
          <ul>
            <li
              class="flex cursorP"
              v-for="(item, index) in activityList"
              :key="index"
              @click="goRouter('/hunan/skill/skillDetail', item.id)"
            >
              <img :src="item.imgurl" alt="" class="err_image" />
              <div class="text">
                <h3>
                  {{ item.name }}
                  <span class="one" v-show="item.auditstatus == '0'"
                    >未审核</span
                  >
                  <span class="two" v-show="item.auditstatus == '1'"
                    >审核通过</span
                  >
                  <el-popover
                    placement="bottom"
                    title="未通过原因"
                    width="200"
                    trigger="click"
                    content="item.auditMsg"
                  >
                    <span class="three" v-show="item.auditstatus == '2'"
                      >未通过，查看原因</span
                    >
                  </el-popover>
                </h3>
                <p class="day" v-if="getTime(item.matchStartDate) > 0">
                  距离比赛开始还有{{ getTime(item.matchStartDate) }}天
                </p>
                <p class="time">报名时间：{{ item.matchStartDate }}</p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <!-- 盲人按摩培训 -->
      <!-- <section v-show="active == 2">
        <div class="massage">
          <ul>
            <li
              class="flex cursorP"
              v-for="item in 3"
              :key="item"
              @click="goRouter('/hunan/skill/skillDetail', item.id)"
            >
              <h3>
                2020年盲人按摩培训
                <span class="one">未审核</span>
                <span class="two" v-show="item.auditstatus == '通过'"
                  >审核通过</span
                >
                <el-popover
                  placement="bottom"
                  title="未通过原因"
                  width="200"
                  trigger="click"
                  content="item.auditMsg"
                >
                  <span class="three" v-show="item.auditstatus == '不通过'"
                    >未通过，查看原因</span
                  >
                </el-popover>
              </h3>
              <div>
                <p>培训时间：2020年11月01日至2020年11月10日</p>
                <p>培训地点：湖南省残疾人就业服务指导中心</p>
              </div>
            </li>
          </ul>
        </div>
      </section> -->
      <div class="pages">
        <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.pageNum"
          :page-size="params.pageSize"
          layout="prev, pager, next, total"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "column",
  data() {
    return {
      total: 0,
      flexibleList: [],
      activityList: [],
      params: {
        pageNum: 1,
        pageSize: 4,
        name: "",
        auditStatus: "",
        applyStart:'',
        applyEnd:'',
      },
      tab: ["灵活就业报名", "竞赛报名"],
      active: 0,
    };
  },
  methods: {
    tabs(i) {
      this.active = i;
      if (this.active == 1) {
        this.skillContest();
      }
    },
    //灵活就业
    async flexibleSignUp() {
      this.loading = true;
      let res = await this.$axios.post(
        "/api/app-jycy-henan/henan/lhjy/apply/list",
        this.params
      );
      if (res.data.success) {
        this.flexibleList = res.data.data.records;
      } else {
        this.$message.error(res.data.msg);
        this.loading = false;
      }
    },
    //技能竞赛
    async skillContest() {
      try {
        this.loading = true;
        let res = await this.$api.skillContest(this.params);
        if (res.data.success) {
          this.activityList = res.data.data.list;
          this.total = res.data.data.total;
          this.loading = false;
          console.log(this.activityList);
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    goRouter(url, id) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
    getTime(time) {
      let date = Date.parse(new Date(time));
      let today = Date.parse(new Date());
      let day = date - today;
      let days = Math.floor(day / (24 * 3600 * 1000));
      return days;
    },

    //分页
    handleCurrentChange(val) {
      this.params.pageNum = val;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
    },
  },
  created() {
    this.flexibleSignUp();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/public";
.world {
  padding: 32px 0;
  margin: 0 20px;
  .input {
    margin-top: 20px;
    /deep/.el-input--mini {
      width: 200px;
    }
    /deep/.el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 140px;
    }
    // .one {
    //   span {
    //     width: 60px;
    //   }
    // }
  }
  .tab {
    padding-bottom: 10px;
    border-bottom: 1px solid @e1;

    li {
      position: relative;
      width: 140px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }

    li:before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 20px;
      background: @e1;
    }

    li:last-child:before {
      display: none;
    }

    li.active {
      color: @00;
    }

    li.active::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -10px;
      transform: translateX(-50%);
      width: 115px;
      height: 2px;
      background: @00;
    }
  }
  .flex {
    display: flex;
  }
  .flexible{
        ul {
      margin-top: 24px;
      li {
        margin-bottom: 20px;
        img {
          width: 176px;
          height: 120px;
          border-radius: 6px;
          margin-right: 17px;
        }
        .text {
          position: relative;
          &::after{
            content: "";
            position: absolute;
            top: 97px;
            left: 0;
            width: 100%;
            height: 1px;
            background: #000000;
            opacity: 0.05;
          }
          h3 {
            font-size: 18px;
            font-weight: 600;
            margin: 8px 0 13px 0;
            width: 945px;
            position: relative;
            span {
              font-size: 16px;
              font-family: PingFang SC;
              color: #666666;
              line-height: 14px;
              position: absolute;
              right: 0;
              top: 3px;
            }
            .two {
              color: #28a46d;
            }
            .three {
              color: #e62220;
            }
          }
          p {
            color: #666666;
            font-size: 14px;
            line-height: 14px;
          }
          .day {
            margin-bottom: 34px;
            color: #e62220;
          }
          .time {
            padding-top: 5px;
          }
        }
      }
    }
  }
  .activity {
    ul {
      margin-top: 24px;
      li {
        margin-bottom: 20px;
        img {
          width: 176px;
          height: 120px;
          border-radius: 6px;
          margin-right: 17px;
        }
        .text {
          position: relative;
          h3 {
            font-size: 18px;
            font-weight: 600;
            margin: 8px 0 13px 0;
            width: 740px;
            position: relative;
            span {
              font-size: 16px;
              font-family: PingFang SC;
              color: #666666;
              line-height: 14px;
              position: absolute;
              right: 0;
              top: 3px;
            }
            .two {
              color: #28a46d;
            }
            .three {
              color: #e62220;
            }
          }
          p {
            color: #666666;
            font-size: 14px;
            line-height: 14px;
          }
          .day {
            margin-bottom: 34px;
            color: #e62220;
          }
          .time {
            position: absolute;
            top: 92px;
          }
        }
      }
    }
  }
  .massage {
    margin-left: 4px;
    ul {
      margin-top: 24px;
      li {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        h3 {
          font-size: 18px;
          font-weight: 600;
          margin: 8px 0 13px 0;
          position: relative;
          span {
            font-size: 16px;
            font-family: PingFang SC;
            color: #666666;
            line-height: 14px;
            position: absolute;
            right: 0;
            top: 3px;
          }
          .two {
            color: #28a46d;
          }
          .three {
            color: #e62220;
          }
        }
        p {
          display: inline-block;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 14px;
        }
        p:first-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.wrapper {
  background: #ffffff;
}
/deep/ .el-loading-spinner {
  top: 30%;
}
.more {
  color: #00924c;
  text-decoration: underline;
  font-size: 14px;
}
/deep/ .el-loading-mask {
  top: 28px;
  right: 70px;
}
/deep/ .el-loading-spinner {
  top: 5%;
}
.pages {
  padding-top: 90px;
}
</style>
